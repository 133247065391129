
import React from 'react'
import CustomerService from '../../../templates/customerService'

const CustomerServiceEsPage = () => {
    return (
        <CustomerService lang="es">
        </CustomerService>
    )
}

export default CustomerServiceEsPage
